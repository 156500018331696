// EisenhowerMatrix.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import RenderDroppedCard from '../components/RenderDroppedCards';
import useConfirm from '../components/useConfirm';
import i18n from '../i18n'; // Import for localization

const EisenhowerMatrix = () => {
    const { gameId, name } = useParams();
    const [code, setCode] = useState("");
    const [answers, setAnswers] = useState([]);
    const [comments, setComments] = useState([]);
    const [matrix, setMatrix] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [question, setQuestion] = useState("");
    const [cardName, setCardName] = useState("");
    const [cardNames, setCardNames] = useState([]);
    const [importantAndUrgent, setImportantAndUrgent] = useState([]);
    const [importantAndNotUrgent, setImportantAndNotUrgent] = useState([]);
    const [notImportantAndUrgent, setNotImportantAndUrgent] = useState([]);
    const [notImportantAndNotUrgent, setNotImportantAndNotUrgent] = useState([]);
    const [shouldRenderNextAnswer, setShouldRenderNextAnswer] = useState(true);
    const [endingMode, setEndingMode] = useState("");
    const [index, setIndex] = useState(0);
    const navigate = useNavigate();

    const [ConfirmationDialog, confirm] = useConfirm();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/spelen");
        } else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.role !== "Notulist") {
                navigate("/spelen");
            } else {
                fetchAnswers();
                fetchEndingMode();
                console.log("authorized");
            }
        }
    }, []);

    const fetchAnswers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/getanswers/${gameId}/${name}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            const data = await response.json();

            const questions = data.gameSession.map(card => card.cardnumber);
            const answers = data.gameSession.map(card => {
                if (card.answer_D) {
                    return [...card.card_answers, `D - ${card.answer_D}`];
                } else {
                    return card.card_answers;
                }
            });
            const comments = data.gameSession.map(card => card.toelichting);

            setQuestions(questions);
            setAnswers(answers);
            setComments(comments);
            setMatrix(new Array(questions.length).fill(''));
            setCardNames(new Array(questions.length).fill(''));

            await getCard(questions[index]);
        } catch (error) {
            console.error("Error fetching answers:", error);
        }
    };

    const fetchEndingMode = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/game/${gameId}/endingMode`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            const data = await response.json();
            setEndingMode(data.endingMode);
        } catch (error) {
            console.error("Error fetching ending mode:", error);
        }
    };

    const getCard = async (code) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/getcard/${code}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setCode(data.code);
            setQuestion(data.question);
            setCardName(data.name);
            const newCardNames = [...cardNames];
            newCardNames[index] = data.name;
            setCardNames(newCardNames);
            const cardColorMap = {
                'F': ["rgb(250,175,24)", "rgb(253,227,174)"],
                'G': ["rgb(243,110,49)", "rgb(250,204,183)"],
                'V': ["rgb(200,32,38)", "rgb(229,184,185)"],
                'R': ["rgb(144,38,143)", "rgb(216,179,215)"],
                'S': ["rgb(16,116,188)", "rgb(171,206,231)"],
                'W': ["rgb(21,192,242)", "rgb(173,223,250)"],
                'O': ["rgb(0,124,143)", "rgb(166,209,215)"],
                'A': ["rgb(58,181,74)", "rgb(186,229,191)"],
            };
            const [titleColor, questionColor] = cardColorMap[data.code.charAt(0)] || ["rgb(50,50,50)", "rgb(60,60,60)"];
            document.getElementsByClassName("titelbalk")[0].style.backgroundColor = titleColor;
            document.getElementsByClassName("vraagbalk")[0].style.backgroundColor = questionColor;
        } catch (error) {
            console.error('Error getting card:', error);
        }
    };

    useEffect(() => {
        if (questions.length > 0) {
            getCard(questions[index]);
        }
    }, [index, questions]);

    const moveAnswer = (content, toCorner, prevCorner) => {
        const movedAnswer = content;
        const moveAnswerIndex = parseInt(movedAnswer.split(' ')[0]) - 1;

        switch (toCorner) {
            case i18n.t('ImportantAndUrgent'):
                setImportantAndUrgent((prev) => [...prev, movedAnswer]);
                break;
            case i18n.t('ImportantAndNotUrgent'):
                setImportantAndNotUrgent((prev) => [...prev, movedAnswer]);
                break;
            case i18n.t('NotImportantAndUrgent'):
                setNotImportantAndUrgent((prev) => [...prev, movedAnswer]);
                break;
            case i18n.t('NotImportantAndNotUrgent'):
                setNotImportantAndNotUrgent((prev) => [...prev, movedAnswer]);
                break;
            default:
                break;
        }
        switch (prevCorner) {
            case i18n.t('ImportantAndUrgent'):
                setImportantAndUrgent((prev) => prev.filter((answer) => answer !== movedAnswer));
                break;
            case i18n.t('ImportantAndNotUrgent'):
                setImportantAndNotUrgent((prev) => prev.filter((answer) => answer !== movedAnswer));
                break;
            case i18n.t('NotImportantAndUrgent'):
                setNotImportantAndUrgent((prev) => prev.filter((answer) => answer !== movedAnswer));
                break;
            case i18n.t('NotImportantAndNotUrgent'):
                setNotImportantAndNotUrgent((prev) => prev.filter((answer) => answer !== movedAnswer));
                break;
            default:
                break;
        }
        const updatedMatrix = [...matrix];
        updatedMatrix[moveAnswerIndex] = toCorner;
        setMatrix(updatedMatrix);
    };

    const handleDrop = (e, corner) => {
        const content = JSON.parse(e.dataTransfer.getData('content'));
        const checkIndex = parseInt(content.answer.split(' ')[0]) - 1;
        if (checkIndex === index) {
            setShouldRenderNextAnswer(true);
            setIndex((prevIndex) => prevIndex + 1);
        } else {
            setShouldRenderNextAnswer(true);
        }
        if (!content.matrix) {
            moveAnswer(content.answer, corner);
        } else {
            if (content.matrix === corner) {
                return;
            } else {
                moveAnswer(content.answer, corner, content.matrix);
            }
        }
    };

    const renderNextAnswer = () => {
        const currentAnswer = answers[index];
        const currentCard = questions[index];

        if (!currentAnswer) return;
        return (
            <div
                className="matrixcard"
                key={`center-${index}`}
                draggable
                onDragStart={(e) => {
                    e.dataTransfer.setData('content', JSON.stringify({ "answer": `${index + 1} ${question}` }));
                }}
            >
                <div className="titelbalk">
                    <h6 className='cardname'>{` ${cardName}`}</h6>
                    <h6>{`|`}</h6>
                    <h5>{`${code}`}</h5>
                </div>
                <div className="vraagbalk">
                    <h7>{question}</h7>
                </div>
                <div className="antwoordbalk">
                    <div className="antwoordnotesdiv">
                        <h7 className="underline">{i18n.t('Answer')}:</h7>
                        {currentAnswer.map((answer, idx) => (
                            <div key={`answer-${idx}`}>
                                <h7>{answer}</h7>
                            </div>
                        ))}
                    </div>
                    <div className="antwoordnotesdiv">
                        <h7 className="underline">{i18n.t('Note')}:</h7>
                        <h7>{comments[index]}</h7>
                    </div>
                </div>
            </div>
        );
    };

    const closeGame = async () => {
        const confirmed = await confirm(
            i18n.t('ConfirmNextPhaseTitle'),
            i18n.t('ConfirmNextPhaseMessage')
        );

        if (confirmed) {
            try {
                const closeResponse = await fetch(`${process.env.REACT_APP_API_URL}/closefase2/${gameId}/${name}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ matrix: matrix }),
                });
                const closeData = await closeResponse.json();
                console.log('Game closed:', closeData);
                if (endingMode === 'offline') {
                    await fetch(`${process.env.REACT_APP_API_URL}/closeoffline/${gameId}/${name}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });
                    navigate(`/feedback`);
                } else {
                    navigate(`/afronding-selection/${gameId}/${name}`);
                }
            } catch (error) {
                console.error('Fout met game sluiten:', error);
            }
        }
    };

    return (
        <div className="matrixpage">
            <div className="eisenhower-matrix">
                <div className="matrixrow1">
                    <div className="cellwrapper2">
                        <div className="invisiblecell1"></div>
                    </div>
                    <div className="cellwrapper">
                        <div className="titlecell1">
                            <h2>{i18n.t('Urgent')}</h2>
                        </div>
                    </div>
                    <div className="cellwrapper">
                        <div className="titlecell1">
                            <h2>{i18n.t('NotUrgent')}</h2>
                        </div>
                    </div>
                </div>
                <div className="matrixrow">
                    <div className="cellwrapper1">
                        <div className="titlecell">
                            <h2>{i18n.t('Important')}</h2>
                        </div>
                    </div>
                    <div className="cellwrapper">
                        <div className="belangrijk-urgent"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => handleDrop(e, i18n.t('ImportantAndUrgent'))}
                        >
                            {importantAndUrgent.map((answer, idx) => (
                                <div
                                    className="droppedquestions"
                                    draggable
                                    onDragStart={(e) => {
                                        e.dataTransfer.setData('content', JSON.stringify({ answer, "matrix": i18n.t('ImportantAndUrgent') }));
                                    }}
                                    key={`important-urgent-${idx}`}>
                                    <RenderDroppedCard
                                        key={idx}
                                        answer={answer}
                                        questions={questions}
                                        cardName={cardNames}
                                        answers={answers}
                                        comments={comments}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="cellwrapper">
                        <div className="belangrijk-niet-urgent"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => handleDrop(e, i18n.t('ImportantAndNotUrgent'))}
                        >
                            {importantAndNotUrgent.map((answer, idx) => (
                                <div
                                    className="droppedquestions"
                                    draggable
                                    onDragStart={(e) => {
                                        e.dataTransfer.setData('content', JSON.stringify({ answer, "matrix": i18n.t('ImportantAndNotUrgent') }));
                                    }}
                                    key={`important-not-urgent-${idx}`}>
                                    <RenderDroppedCard
                                        key={idx}
                                        answer={answer}
                                        questions={questions}
                                        cardName={cardNames}
                                        answers={answers}
                                        comments={comments}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="matrixrow">
                    <div className="cellwrapper1">
                        <div className="titlecell">
                            <h2>{i18n.t('NotImportant')}</h2>
                        </div>
                    </div>
                    <div className="cellwrapper">
                        <div className="niet-belangrijk-urgent"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => handleDrop(e, i18n.t('NotImportantAndUrgent'))}
                        >
                            {notImportantAndUrgent.map((answer, idx) => (
                                <div
                                    className="droppedquestions"
                                    draggable
                                    onDragStart={(e) => {
                                        e.dataTransfer.setData('content', JSON.stringify({ answer, "matrix": i18n.t('NotImportantAndUrgent') }));
                                    }}
                                    key={`not-important-urgent-${idx}`}>
                                    <RenderDroppedCard
                                        key={idx}
                                        answer={answer}
                                        questions={questions}
                                        cardName={cardNames}
                                        answers={answers}
                                        comments={comments}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="cellwrapper">
                        <div className="niet-belangrijk-niet-urgent"
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => handleDrop(e, i18n.t('NotImportantAndNotUrgent'))}
                        >
                            {notImportantAndNotUrgent.map((answer, idx) => (
                                <div
                                    className="droppedquestions"
                                    draggable
                                    onDragStart={(e) => {
                                        e.dataTransfer.setData('content', JSON.stringify({ answer, "matrix": i18n.t('NotImportantAndNotUrgent') }));
                                    }}
                                    key={`not-important-not-urgent-${idx}`}>
                                    <RenderDroppedCard
                                        key={idx}
                                        answer={answer}
                                        questions={questions}
                                        cardName={cardNames}
                                        answers={answers}
                                        comments={comments}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="antwoordtodrag">
                <div className="center-area" onDrop={(e) => handleDrop(e, 'test')}>
                    {shouldRenderNextAnswer ? renderNextAnswer() : index === 0 && window.location.reload()}
                </div>
                {index > questions.length - 1 ? (
                    <>
                        {endingMode === 'offline' ? (
                            <button className="upperbuttons" onClick={closeGame}>{i18n.t('FinishGame')}</button>
                        ) : (
                            <button className="upperbuttons" onClick={closeGame}>{i18n.t('ToSelectCompletion')}</button>
                        )}
                    </>
                ) : (
                    <>
                        <h1>{i18n.t('DragCardToBox')}</h1>
                    </>
                )}
            </div>
            <ConfirmationDialog />
        </div>
    );
}

export default EisenhowerMatrix;
