// Account.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Alert from "../components/Alert";
import useConfirm from "../components/useConfirm";
import i18n from '../i18n'; // Import the i18n instance

const Account = () => {
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const [bedrijf, setBedrijf] = useState("");
    const [email, setEmail] = useState("");
    const [emailPassword, setEmailPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [ConfirmationDialog, confirm] = useConfirm();
    const [language, setLanguage] = useState(i18n.language); // State to trigger re-render

    const showAlertMessage = (message) => {
        setAlertMessage(message);
        setShowAlert(true);
    };
    
    const handleCloseAlert = () => {
        setShowAlert(false);
        setAlertMessage('');
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            if (decoded.role !== "Spelbegeleider") {
                navigate('/login'); 
            } else {
                setUserId(decoded.userId);
                fetchData(decoded.userId);
            }
        } else {
            navigate('/login');
        }
    }, [navigate]);

    const fetchData = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/getuserdata/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const data = await response.json();
            console.log(data);
            setBedrijf(data.company);
            setEmail(data.email);
        } catch (error) {
            console.error(i18n.t('ErrorFetchingUserData'), error);
        }
    };

    const handleEmailChange = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/changeemail/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newemail: newEmail, confirmemail: confirmEmail, password: emailPassword }),
            });
            const data = await response.json();
            showAlertMessage(`${data.status}: ${data.message}`);
        } catch (error) {
            console.error(i18n.t('ErrorChangingEmail'), error);
        }
    };

    const handleAccountCancellation = async () => {
        const confirmed = await confirm(
            i18n.t('CancelAccount'),
            i18n.t('CancelAccountConfirmation')
        );
    
        if (confirmed) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/cancelaccount/${userId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const data = await response.json();
                showAlertMessage(`${data.status}: ${data.message}`);
                if (data.status === "Success") {
                    showAlertMessage(i18n.t('AccountCancelled'));
                }
            } catch (error) {
                console.error(i18n.t('ErrorCancellingAccount'), error);
            }
        }
    };

    const handleChangePassword = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/changepassword/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ oldPassword, newPassword, confirmPassword }),
            });
            const data = await response.json();
            showAlertMessage(`${data.status}: ${data.message}`);
        } catch (error) {
            console.error(i18n.t('ErrorChangingPassword'), error);
        }
    };
    
    if (!userId) {
        return <div>{i18n.t('Loading')}</div>;
    }

    return (
        <div>
            {/* Custom Alert Component */}
            <Alert show={showAlert} handleClose={handleCloseAlert} title={alertMessage} />

            {/* Custom Confirm Component */}
            <ConfirmationDialog />

            <div className="accountdiv">
                <h1>{i18n.t('Account')}</h1>
                <h5>{email}</h5>
                <h5>{bedrijf}</h5>
                <div className="accountinputdiv">
                    <h5>{i18n.t('ChangeEmail')}</h5>
                    <input 
                        type="text" 
                        placeholder={i18n.t('NewEmail')} 
                        onChange={(e) => setNewEmail(e.target.value)} 
                        value={newEmail} 
                    />
                    <input 
                        type="text" 
                        placeholder={i18n.t('ConfirmNewEmail')} 
                        onChange={(e) => setConfirmEmail(e.target.value)} 
                        value={confirmEmail} 
                    />
                    <input 
                        type="password" 
                        placeholder={i18n.t('Password')} 
                        onChange={(e) => setEmailPassword(e.target.value)} 
                        value={emailPassword} 
                    />
                    <button className="upperbuttons" onClick={handleEmailChange}>{i18n.t('Apply')}</button>
                </div>
                <div className="accountinputdiv">
                    <h5>{i18n.t('ChangePassword')}</h5>
                    <input 
                        type="password" 
                        placeholder={i18n.t('CurrentPassword')} 
                        onChange={(e) => setOldPassword(e.target.value)} 
                        value={oldPassword} 
                    />
                    <input 
                        type="password" 
                        placeholder={i18n.t('NewPassword')} 
                        onChange={(e) => setNewPassword(e.target.value)} 
                        value={newPassword} 
                    />
                    <input 
                        type="password" 
                        placeholder={i18n.t('ConfirmNewPassword')} 
                        onChange={(e) => setConfirmPassword(e.target.value)} 
                        value={confirmPassword} 
                    />
                    <button className="upperbuttons" onClick={handleChangePassword}>{i18n.t('Apply')}</button>
                </div>
                <button className="noaccount" onClick={handleAccountCancellation}>{i18n.t('CancelAccount')}</button>     
            </div>
        </div>
    );
};

export default Account;
