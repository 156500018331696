// AutomaticAfronding.js
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import i18n from '../i18n'; // Import the i18n instance

function AutomaticAfronding() {
  const { gameId, name } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
        navigate("/spelen");
    } else {
        const decodedToken = jwtDecode(token);
        if (decodedToken.role === "Notulist") {
           closeGame(); 
        } else {
            navigate("/spelen");
        }
    }
  }, [navigate, gameId, name]);
  
  const closeGame = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/closeoffline/${gameId}/${name}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log(data);
      navigate("/feedback");
    } catch (error) {
      console.error(i18n.t("ErrorClosingGame"), error);
    }
  };

  return null;
}

export default AutomaticAfronding;
