import React, { useState } from 'react';
import Alert from "../components/Alert";

const Feedback = () => {
    const [comment, setComment] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const showAlertMessage = (message) => {
        setAlertMessage(message);
        setShowAlert(true);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
        setAlertMessage('');
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!comment) {
            showAlertMessage("Geef een opmerking");
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/addbugreport`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ details: comment }),
            });

            const responseData = await response.json();
            console.log(responseData);
            showAlertMessage("Bedankt voor je feedback!");
        } catch (error) {
            console.error("Fout met afronding indienen:", error);
        }
    };

    return (
        <div className="feedbackcontainer">
            <h5>Heb je verbeterpunten gevonden?</h5>
            <textarea
                className="feedbackaera"
                placeholder="Vertel ons waar je graag verandering in wilt zien..."
                value={comment}
                onChange={(e) => setComment(e.target.value)}
            />
            <button
                className="upperbuttons feedbackbuttonsave"
                onClick={handleSubmit}
            >
                Plaats opmerking
            </button>

            {/* Custom Alert Component */}
            <Alert show={showAlert} handleClose={handleCloseAlert} title={alertMessage} />
        </div>
    );
};

export default Feedback;
