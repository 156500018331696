// Keuzemenu2.js
import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import i18n from '../i18n'; // Import for localization
import useLanguageChange from '../hooks/useLanguageChange';

const Keuzemenu2 = () => {
    const navigate = useNavigate();
    useLanguageChange();
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/spelen");
        } else {
            const decodedToken = jwtDecode(token);
            console.log(decodedToken);
            if (decodedToken.role !== "Spelbegeleider") {
                navigate("/spelen");
            }
        }
    }, [navigate]);

    return (
        <div className="keuzemenu">
            {/*<div className="kruimelpad">
                <a href="/">Home</a>
                <p>/</p>
                <a href="/spelenkeuze">Dashboard</a>
            </div>*/}
            <div className="keuzes"> 
                <h4>{i18n.t('WelcomeCoach')}</h4>
                <ul className="keuzelist">
                    <li>
                        <Link to="/Dashboard">{i18n.t('ManageGameSessions')}</Link>
                    </li>
                    <li>
                        <Link to="/Archiefpagina">{i18n.t('ReportOverview')}</Link>
                    </li>
                </ul>
                <ul className="keuzelist">
                    <li>
                        <Link to="/Kaartkeuze">{i18n.t('CardsOverview')}</Link>
                    </li> 
                    <li>
                        <Link to="/DownloadSpelmateriaal">{i18n.t('DownloadGameMaterials')}</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Keuzemenu2;
