import { Link } from "react-router-dom";
import i18n from '../i18n';
import useLanguageChange from '../hooks/useLanguageChange'; // Import the hook

const Keuzemenu = () => {
  useLanguageChange(); // Call the hook to handle language changes

  return (
    <div className="keuzemenu">
      <div className="keuzes">
        <h4 style={{ color: 'black' }}>
          {i18n.t('WelcomeLoginPage')}<br /> {i18n.t('SelectRole')}
        </h4>
        <ul className="keuzelist">
          <li>
            <Link to="/login/spelbegeleider">{i18n.t('RoleCoach')}</Link>
          </li>
          <li>
            <Link to="/login/notulist">{i18n.t('RoleReporter')}</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Keuzemenu;
