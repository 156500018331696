// Trainen.js
import React, { useState } from 'react';
import i18n from '../i18n'; // Import i18n for translations

const Trainen = () => {
    const [question1, setQuestion1] = useState(false);
    const [question2, setQuestion2] = useState(false);
    const [question3, setQuestion3] = useState(false);

    const toggleQuestion = (number) => {
        switch (number) {
            case 1:
                setQuestion1(!question1);
                break;
            case 2:
                setQuestion2(!question2);
                break;
            case 3:
                setQuestion3(!question3);
                break;
            default:
                break;
        }
    }

    return (
        <div className="Spelen">
            <h5>FAQ</h5>
            <div className="faqdiv">
                <button onClick={() => toggleQuestion(1)}>
                    {i18n.t('FAQQuestion1')}<span>↓</span>
                </button>
                {question1 && 
                <p>{i18n.t('FAQAnswer1')}</p>
                }
            </div>
            <div className="faqdiv">
                <button onClick={() => toggleQuestion(2)}>
                    {i18n.t('FAQQuestion2')}<span>↓</span>
                </button>
                {question2 &&
                <p>{i18n.t('FAQAnswer2')}</p>
                }
            </div>
            <div className="faqdiv">
                <button onClick={() => toggleQuestion(3)}>
                    {i18n.t('FAQQuestion3')}<span>↓</span>
                </button>
                {question3 &&
                <p>{i18n.t('FAQAnswer3')}</p>
                }
            </div>
        </div>
    );
};

export default Trainen;
