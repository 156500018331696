import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {jwtDecode} from "jwt-decode";
import i18n from '../i18n'; // Import i18n instance
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const Notulistlogin = () => {
  const [code, setCode] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [gameId, setGameId] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize the translation function

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.role === "Notulist") {
        setGameId(decoded.gameId);
        setName(decoded.name);
        setLoggedIn(true);
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(`${t('LoggingInWithNotulistCode')}: ${code}`);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login/notulist/${code}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (data.status === "Error" && data.message === "No active game session or already filled") {
        alert(t('NoActiveGameOrAlreadyFilled'));
        return;
      } else if (response.ok && data.status === "Success") {
        localStorage.setItem("token", data.token);
        window.location.reload();
      } else {
        console.error(t('LoginFailed'), data.message);
      }
    } catch (error) {
      console.error(t('LoginError'), error);
    }
  };

  if (loggedIn) {
    navigate(`/antwoordpagina/${gameId}/${name}`);
  }

  const backToSpelen = () => {
    navigate('/spelen');
  };

  return (
    <div className="login-register">
      <div className="accountdiv">
        <h5>{t('NotulistLogin')}</h5>
        <button className="upperbuttons" onClick={backToSpelen}>{t('Back')}</button>
        <br />
        <h5>{t('EnterNotulistCode')}</h5>
        <input
          type="text"
          minLength="6"
          maxLength="6"
          placeholder={t('PlaceholderCode')}
          onChange={(e) => setCode(e.target.value)}
          value={code}
        />
        <button className="upperbuttons" onClick={handleSubmit}>{t('Login')}</button>
      </div>
    </div>
  );
};

export default Notulistlogin;
