import { Link, useNavigate } from "react-router-dom";
import logo from "./../pictures/logo.png";
import { useState, useEffect } from "react";
import {jwtDecode} from "jwt-decode";
import i18n from '../i18n';
import Button from '@mui/material/Button';
import './NavbarStyles.css';
import { Select, MenuItem } from '@mui/material';


const Navbar = () => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [notulist, setNotulist] = useState(false);
    const [spelbegeleider, setSpelbegeleider] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [language, setLanguage] = useState(i18n.language || 'nl');
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decoded = jwtDecode(token);
            if (decoded.role === "Notulist") {
                setNotulist(true);
                setSpelbegeleider(false);
            } else if (decoded.role === "Spelbegeleider") {
                setSpelbegeleider(true);
                setNotulist(false);
            } else if (decoded.role === "Admin") {
                setAdmin(true);
                setNotulist(false);
                setSpelbegeleider(false);
            }
        }
    }, [navigate]);

    useEffect(() => {
        const tokenFromStorage = localStorage.getItem('token');
        setToken(tokenFromStorage);
    }, []);

    useEffect(() => {
        const handleLanguageChanged = (lng) => {
            setLanguage(lng);
        };

        i18n.on('languageChanged', handleLanguageChanged);

        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        setToken(null);
        window.location.reload();
    };

    const handleLogin = () => {
        navigate('/spelen');
    };

    const handleLanguageChange = (e) => {
        const newLanguage = e.target.value;
        i18n.changeLanguage(newLanguage).then(() => {
            setLanguage(newLanguage); // Force re-render
            localStorage.setItem('language', newLanguage); // Save to local storage
        });
    };

    return (
        <header>
            <div className="navbar">
                <img src={logo} alt="Logo" className="navbar-logo" />
                <ul className="navbar-items">
                    {!notulist && (
                        <li className="navopties">
                            <Button component="a" href="https://workplacegame.nl/" target="_blank" rel="noopener noreferrer" className="nav-button">
                                {i18n.t('Home')}
                            </Button>
                        </li>
                    )}
                    {spelbegeleider && (
                        <li className="navopties">
                            <Button component={Link} to="/spelenkeuze" className="nav-button">
                                {i18n.t('Dashboard')}
                            </Button>
                        </li>
                    )}
                    {admin && (
                        <li className="navopties">
                            <Button component={Link} to="/admin" className="nav-button">
                                {i18n.t('Dashboard')}
                            </Button>
                        </li>
                    )}
                    {spelbegeleider && (
                        <li className="navopties">
                            <Button onClick={() => navigate('/account')} className="nav-button">
                                {i18n.t('Account')}
                            </Button>
                        </li>
                    )}
                    {token ? (
                        <li className="navopties">
                            <Button onClick={handleLogout} className="nav-button nav-button-orange">
                                {i18n.t('Logout')}
                            </Button>
                        </li>
                    ) : (
                        <li className="navopties">
                            <Button onClick={handleLogin} className="nav-button nav-button-orange">
                                {i18n.t('Login')}
                            </Button>
                        </li>
                    )}

                        <li className="navopties">
                            <Select
                                value={language}
                                onChange={handleLanguageChange}
                                variant="outlined"
                                sx={{
                                    backgroundColor: 'rgb(0, 182, 196)',
                                    color: 'black',
                                    borderRadius: '12px',
                                    padding: '0 1rem',
                                    fontFamily: 'Poppins',
                                    fontSize: 'medium',
                                    fontWeight: 'bold',
                                    height: '41px',
                                    '& .MuiSelect-icon': {
                                        color: 'black',
                                    },
                                    '&:hover': {
                                        backgroundColor: 'rgb(0, 230, 247)',
                                    },
                                }}
                        >
                        <MenuItem value="nl">Nederlands</MenuItem>
                        <MenuItem value="en">English</MenuItem>
                    </Select>

                    
                    </li>
                </ul>
            </div>
        </header>
    );
};

export default Navbar;
