import { useEffect, useState } from 'react';
import i18n from '../i18n'; // Adjust the path based on your project structure

const useLanguageChange = () => {
  const [_, setRerender] = useState(0); // State to trigger re-renders

  useEffect(() => {
    const handleLanguageChanged = () => {
      setRerender((prev) => prev + 1); // Update state to trigger re-render
    };

    i18n.on('languageChanged', handleLanguageChanged);

    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, []);
};

export default useLanguageChange;
