// AdminCodePage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Alert from "../components/Alert";
import i18n from '../i18n'; // Import i18n instance

const AdminCodePage = () => {
  const [numCodes1, setNumCodes1] = useState('');
  const [spelCode, setSpelCode] = useState('');
  const [bedrijf, setBedrijf] = useState('');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [language, setLanguage] = useState(i18n.language); // Trigger re-render for language changes

  const showAlertMessage = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    setAlertMessage('');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      const decodedToken = jwtDecode(token);
      if (decodedToken.role !== 'Admin') {
        navigate('/');
      }
    }
  }, [navigate]);

  const generateSpelcodes = async () => {
    if (numCodes1 < 1) {
      showAlertMessage(i18n.t('EnterNumberGreaterThanZero'));
      return;
    }
    try {
      const token = localStorage.getItem('token'); // Retrieve token here
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/generatespelcodes`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify({ numCodes1 }),
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'spelcodes.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(i18n.t('ErrorGeneratingCodes'), error);
    }
  };

  const zoekGebruiker = async () => {
    try {
      const token = localStorage.getItem('token'); // Retrieve token here
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/zoekgebruiker`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify({ spelCode }),
      });
      const data = await response.json();
      if (data.error) {
        showAlertMessage(data.error);
        setEmail('');
        setBedrijf('');
        setUserId('');
      } else {
        setEmail(data.email);
        setBedrijf(data.bedrijf);
        setUserId(data.userId);
      }
    } catch (error) {
      console.error(i18n.t('ErrorSearchingForUser'), error);
    }
  };

  const verlengAbonnement = async () => {
    try {
      const token = localStorage.getItem('token'); // Retrieve token here
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/extendsubscription/${userId}`, {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json',  
          'Authorization': `Bearer ${token}` 
        },
      });
      const data = await response.json();
      if (data.error) {
        showAlertMessage(data.error);
      } else {
        showAlertMessage(i18n.t('SubscriptionExtended'));
      }
    } catch (error) {
      console.error(i18n.t('ErrorExtendingSubscription'), error);
    }
  };

  return (
    <div className="admindiv">
      {/* Custom Alert Component */}
      <Alert show={showAlert} handleClose={handleCloseAlert} title={alertMessage} />

      <button className="upperbuttons" onClick={() => navigate('/admin')}>{i18n.t('Back')}</button>
      <h1>{i18n.t('GenerateGameCodesAndExtendSubscription')}</h1>
      <h5>{i18n.t('GameCodes')}</h5>
      <div className="generatordiv">
        <div className="codegenerator">
          <div className="admincodeinput">
            <input
              type="number"
              value={numCodes1}
              placeholder={i18n.t('NumberOfCodes')}
              onChange={(e) => setNumCodes1(e.target.value)}
            />
            <button onClick={generateSpelcodes}>{i18n.t('DownloadCSV')}</button>
          </div>
        </div>
      </div>
      <h5>{i18n.t('ExtendSubscription')}</h5>
      <div className="generatordiv">
        <div className="codegenerator">
          <div className="admincodeinput">
            <input
              type="number"
              value={spelCode}
              placeholder={i18n.t('GameCode')}
              onChange={(e) => setSpelCode(e.target.value)}
            />
            <button onClick={zoekGebruiker}>{i18n.t('SearchUser')}</button>
          </div>
          {email && <p>{i18n.t('Email')}: {email}</p>}
          {bedrijf && <p>{i18n.t('Company')}: {bedrijf}</p>}
          {(email || bedrijf) && <button onClick={verlengAbonnement}>{i18n.t('ExtendSubscription')}</button>}
        </div>
      </div>
    </div>
  );
};

export default AdminCodePage;
