// Adminpaneel.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Alert from "../components/Alert";
import i18n from '../i18n'; // Import i18n instance

const AdminFeedbackPage = () => {
  const [feedback, setFeedback] = useState([]);
  const [bugReports, setBugReports] = useState([]);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [language, setLanguage] = useState(i18n.language); // Trigger re-render for language changes

  const showAlertMessage = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    setAlertMessage('');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      const decodedToken = jwtDecode(token);
      if (decodedToken.role !== 'Admin') {
        navigate('/');
      } else {
        fetchFeedback(token); // Pass token to fetch functions
        fetchBugReports(token);
      }
    }
  }, [navigate]);

  // Fetch feedback from the server with token in Authorization header
  const fetchFeedback = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/getfeedback`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      const data = await response.json();
      setFeedback(data);
    } catch (error) {
      console.error(i18n.t('ErrorFetchingFeedback'), error);
      showAlertMessage(i18n.t('ErrorFetchingFeedbackData'));
    }
  };

  // Fetch bug reports from the server with token in Authorization header
  const fetchBugReports = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/getbugreports`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      const data = await response.json();
      setBugReports(data);
    } catch (error) {
      console.error(i18n.t('ErrorFetchingBugReports'), error);
      showAlertMessage(i18n.t('ErrorFetchingBugReports'));
    }
  };

  // Calculate the average score from the feedback
  const calculateScore = () => {
    if (feedback.length === 0) return 0;
    const totalScore = feedback.reduce((acc, item) => acc + item.nummer, 0);
    return (totalScore / feedback.length).toFixed(2);
  };

  return (
    <div className="admindiv">
      {/* Custom Alert Component */}
      <Alert show={showAlert} handleClose={handleCloseAlert} title={alertMessage} />

      <button className="upperbuttons" onClick={() => navigate('/admin')}>{i18n.t('Back')}</button>
      <h1>{i18n.t('ViewFeedback')}</h1>
      <h5>{i18n.t('PlayerFeedback')}</h5>
      <div className="feedbackdiv">
        <p>{i18n.t('AverageScore')}: {calculateScore()}</p>
        {feedback.map((item, index) => (
          <div className="feedbackitem" key={index}>
            <p>{i18n.t('Score')}: {item.nummer}</p>
            <p>{i18n.t('Details')}: {item.details}</p>
          </div>
        ))}
      </div>
      <h5>{i18n.t('BugReports')}</h5>
      <div className="feedbackdiv">
        {bugReports.map((item, index) => (
          <div className="feedbackitem" key={index}>
            <p>{i18n.t('Details')}: {item.details}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminFeedbackPage;
