// Dashboard.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode'; // Do not change this import
import ActieFile from "./../pdfs/Actie.pdf";
import BesluitFile from "./../pdfs/Besluit.pdf";
import VoorstelFile from "./../pdfs/Voorstel.pdf";
import TegelwijsheidFile from "./../pdfs/Tegelwijsheid.pdf";
import Alert from "../components/Alert";
import useConfirm from "../components/useConfirm";
import i18n from '../i18n'; // Import the i18n instance

const Dashboard = () => {
    const navigate = useNavigate();
    const [userId, setUserId] = useState("");
    const [newGameTitle, setNewGameTitle] = useState("");
    const [archivedGames, setArchivedGames] = useState([]);
    const [activeGames, setActiveGames] = useState([]);
    const [notulistCodes, setNotulistCodes] = useState({ codeA: '', codeB: '' });
    const [numSessions, setNumSessions] = useState(1);
    const [sessionCount, setSessionCount] = useState(0);
    const [gameName, setGameName] = useState("");
    const [mode, setMode] = useState('');
    const [content, setContent] = useState("");
    const [showAlert, setShowAlert] = useState(false); 
    const [alertMessage, setAlertMessage] = useState('');
    const [ConfirmationDialog, confirm] = useConfirm();
    const [language, setLanguage] = useState(i18n.language); // State to trigger re-render

    const showAlertMessage = (message) => {
        setAlertMessage(message);
        setShowAlert(true);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
        setAlertMessage('');
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/spelen");
        } else {
            const decodedToken = jwtDecode(token);
            setUserId(decodedToken.userId);
            if (decodedToken.role === "Spelbegeleider") {
                fetchArchivedGames(decodedToken.userId);
                fetchActiveGames(decodedToken.userId);
                fetchUserDetails(decodedToken.userId);
            } else {
                navigate("/spelen");
            }
        }
    }, [navigate]);

    useEffect(() => {
        const handleLanguageChanged = (lng) => {
            setLanguage(lng);
        };

        i18n.on('languageChanged', handleLanguageChanged);

        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, []);

    const handleModeChange = (e) => {
        const selectedMode = e.target.value;
        setMode(selectedMode);
        setContent(selectedMode === 'offline' ? i18n.t("OfflineCompletionMessage") : "");
    };


    const fetchUserDetails = async (userId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`);
            const userData = await response.json();
            setNotulistCodes({
                codeA: userData.writerCodeA,
                codeB: userData.writerCodeB
            });
            setSessionCount(userData.sessionCount);
            setGameName(userData.name);
        } catch (error) {
            console.error("Fout met gebruikers gegevens ophalen:", error);
        }
    };

    const fetchArchivedGames = async (userId) => {
        if (userId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/archived/${userId}`, {
                    headers: { 'Content-Type': 'application/json' },
                });
                const data = await response.json();
                setArchivedGames(data.filter(game => !game.closed));
            } catch (error) {
                console.error("Fout met gebruikers gegevens ophalen:", error);
            }
        } else {
            console.log('Geen gebruiker om gegevens voor op te halen');
        }
    };

    const fetchActiveGames = async (userId) => {
        if (userId) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/activegames/${userId}`, {
                    headers: { 'Content-Type': 'application/json' },
                });
                const data = await response.json();
                setActiveGames(data);
            } catch (error) {
                console.error("Fout met actieve games ophalen:", error);
            }
        } else {
            console.log('Geen gebruiker om gegevens voor op te halen');
        }
    };

    const handleCreateGame = async (e) => {
        e.preventDefault();
        if (!newGameTitle) {
            showAlertMessage("Vul een titel in"); // Use custom alert
            return;
        }
        if (!mode) {
            showAlertMessage("Selecteer een mode");
            return;
        }
        if (activeGames.length > 0) {
            showAlertMessage("Er is al een actief spel. Sluit het huidige spel voordat u een nieuw spel aanmaakt.");
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/create/${userId}`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    title: newGameTitle,
                    numSessions: numSessions,
                    mode: mode,
                    content: content,
                }),
            });
            window.location.reload();
        } catch (error) {
            console.error("Fout met game aanmaken:", error);
        }
    };

    const openGame = (gameId, name) => {
        navigate(`/notes/${gameId}/${name}`);
    };

    const closeGame = async (gameId, name) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/closenotes/${gameId}/${name}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
            });
            const data = await response.json();
            window.location.reload();
        } catch (error) {
            console.error("Error closing the game:", error);
            alert('Failed to close the game');
        }
    };

    const goToPreviousPage = () => {
        navigate("/spelenkeuze");
    };

    const handleCloseGame = async () => {
        const confirmed = await confirm(
            "Bevestiging",
            "Deze optie is bedoeld om het spel geforceerd te sluiten wanneer er iets is misgegaan en het spel niet kan worden afgerond. Wil je doorgaan?"
        );
   
        if (confirmed) {
            const token = localStorage.getItem("token");
            if (!token) {
                showAlertMessage("User not logged in");
                return;
            }
            const { userId } = jwtDecode(token);
   
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/closegame`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userId }),
                });
                const data = await response.json();
                if (data.success) {
                    showAlertMessage('Game successfully closed');
                    window.location.reload();
                } else {
                    throw new Error(data.message);
                }
            } catch (error) {
                console.error("Error closing the game:", error);
                showAlertMessage('Failed to close the game');
            }
        }
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            showAlertMessage(i18n.t("CodeCopied") + ": " + text);
        }).catch((err) => {
            console.error("Error copying to clipboard:", err);
        });
    };

    return (
        <div>
            {/* Custom Alert Component */}
            <Alert show={showAlert} handleClose={handleCloseAlert} title={alertMessage} />

            {/* Custom Confirm Component */}
            <ConfirmationDialog />

            <div className="dashboarddiv">
                <button className="upperbuttons" onClick={goToPreviousPage}>
                    {i18n.t('PreviousPage')}
                </button>
                <h1>{i18n.t('ManageGameSessions')}</h1>
                {activeGames.length === 0 ? (
                    <>
                        <div className="generatordiv">
                            <h5>{i18n.t('NewGame')}</h5>
                            <input
                                type="text"
                                placeholder={i18n.t('GameTitle')}
                                onChange={(e) => setNewGameTitle(e.target.value)}
                                value={newGameTitle}
                            />
                            <div className="sessioninputs">
                                <div>
                                    <input
                                        type="radio"
                                        value="1"
                                        checked={numSessions === 1}
                                        onChange={() => setNumSessions(1)}
                                    />
                                    <p>{i18n.t('OneGroup')}</p>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        value="2"
                                        checked={numSessions === 2}
                                        onChange={() => setNumSessions(2)}
                                    />
                                    <p>{i18n.t('TwoGroups')}</p>
                                </div>
                            </div>
                            <div className="dashboardmode">
                                <p>{i18n.t('Completion')}:</p>
                                <select value={mode} onChange={handleModeChange}>
                                    <option value="">{i18n.t('SelectCompletionOption')}</option>
                                    <option value="online">{i18n.t('Online')}</option>
                                    <option value="offline">{i18n.t('Offline')}</option>
                                </select>
                            </div>
                            {mode === 'offline' && (
                                <div className="dashboardoffline">
                                    <p>{i18n.t('DownloadCompletionPhase')}</p>
                                    <div>
                                        <a href={TegelwijsheidFile} download="Tegelwijsheid">Tegelwijsheid</a>
                                        <a href={VoorstelFile} download="Voorstel">Voorstel</a>
                                    </div>
                                    <div>
                                        <a href={ActieFile} download="Actie">Actie</a>
                                        <a href={BesluitFile} download="Besluit">Besluit</a>
                                    </div>
                                    <br />
                                </div>
                            )}
                            <button className="upperbuttons" onClick={handleCreateGame}>
                                {i18n.t('CreateGame')}
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="generatordiv">
                            <h5>{i18n.t('CurrentGame')} {gameName}</h5>
                            <p>{i18n.t('SendCodesToReporters')}</p>
                            <p>
                                {i18n.t('NotulistCodeA')} {notulistCodes.codeA}
                                <button className="copybutton" onClick={() => copyToClipboard(notulistCodes.codeA)}>
                                    {i18n.t('Copy')}
                                </button>
                            </p>
                            {sessionCount > 1 && (
                                <p>
                                    {i18n.t('NotulistCodeB')} {notulistCodes.codeB}
                                    <button className="copybutton" onClick={() => copyToClipboard(notulistCodes.codeB)}>
                                        {i18n.t('Copy')}
                                    </button>
                                </p>
                            )}
                            <button className="upperbuttons" onClick={handleCloseGame}>
                                {i18n.t('CloseGame')}
                            </button>
                        </div>
                    </>
                )}
                <div className="generatordiv">
                    <h5>{i18n.t('ReadyForNoting')}</h5>
                    {archivedGames.length > 0 ? (
                        <>
                            {archivedGames.map((game) => (
                                <div key={game.id} className="ongoinggame">
                                    <div className="ongoinggameheader">
                                        <p>{game.title}</p>
                                        <p>{new Date(game.createdAt).toISOString().split('T')[0]}</p>
                                    </div>
                                    {game.activeA && (
                                        <div className="ongoinggamesession">
                                            <p>{i18n.t('SessionA')}</p>
                                            <button onClick={() => openGame(game.id, 'A')}>{i18n.t('OpenGameA')}</button>
                                            <button onClick={() => closeGame(game.id, 'A')}>{i18n.t('CloseGameA')}</button>
                                        </div>
                                    )}
                                    {game.sessionBPresent && game.activeB && (
                                        <div className="ongoinggamesession">
                                            <p>{i18n.t('SessionB')}</p>
                                            <button onClick={() => openGame(game.id, 'B')}>{i18n.t('OpenGameB')}</button>
                                            <button onClick={() => closeGame(game.id, 'B')}>{i18n.t('CloseGameB')}</button>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </>
                    ) : (
                        <p>{i18n.t('NoActiveGamesFound')}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;

