import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Alert from "../components/Alert";

const Adminpaneel = () => {
    const [feedback, setFeedback] = useState([]);
    const [bugReports, setBugReports] = useState([]);
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    
    const showAlertMessage = (message) => {
        setAlertMessage(message);
        setShowAlert(true);
    };
    
    const handleCloseAlert = () => {
        setShowAlert(false);
        setAlertMessage('');
    };
    
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        } else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.role !== 'Admin') {
                navigate('/');
            } else {
                fetchFeedback();
                fetchBugReports();
            }
        }
    }, []);

    // Fetch feedback from the server
    const fetchFeedback = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/getfeedback`);
            const data = await response.json();
            setFeedback(data);
        } catch (error) {
            console.error('Error fetching feedback:', error);
            showAlertMessage('Error fetching feedback data.');
        }
    };
    

    // Fetch bug reports from the server
    const fetchBugReports = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/getbugreports`);
            const data = await response.json();
            setBugReports(data);
        } catch (error) {
            console.error('Error fetching bug reports:', error);
            showAlertMessage('Error fetching bug reports.');
        }
    };
    

    // Calculate the average score from the feedback
    const calculateScore = () => {
        if (feedback.length === 0) return 0;
        const totalScore = feedback.reduce((acc, item) => acc + item.nummer, 0);
        return (totalScore / feedback.length).toFixed(2);
    };

    return (
        <div className="admindiv">
            {/* Custom Alert Component */}
            <Alert show={showAlert} handleClose={handleCloseAlert} title={alertMessage} />
    
            <button className="upperbuttons" onClick={() => navigate('/admin')}>Terug</button> 
            <h1>Feedback inzien</h1>
            <h5>Feedback van spelers</h5>
            <div className="feedbackdiv">
                <p>Gemiddelde score: {calculateScore()}</p>
                {feedback.map((item, index) => (
                    <div className="feedbackitem" key={index}>
                        <p>Score: {item.nummer}</p>
                        <p>Details: {item.details}</p>
                    </div>
                ))}
            </div>
            <h5>Bug reports</h5>
            <div className="feedbackdiv">
                {bugReports.map((item, index) => (
                    <div className="feedbackitem" key={index}>
                        <p>Details: {item.details}</p>
                    </div>
                ))}
            </div>
        </div>
    );
    
};

export default Adminpaneel;
