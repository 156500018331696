// CardsPage.js
import React, { useEffect, useState } from 'react';
import KaartTabel from '../components/kaartTabel'; 
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import i18n from '../i18n'; // Import for localization
import useLanguageChange from '../hooks/useLanguageChange';

const allSubjects = [
  "Aanspreken",
  "text",
  "Archiveren", 
  "Communicatie op afstand", 
  "Drukte", 
  "Eigen plek/claimen", 
  "Gezondheid", 
  "Hierarchie & Management", 
  "ICT & Digitaal werken", 
  "Implementatie", 
  "Juist gebruik van de ruimte", 
  "Leiderschap", 
  "Opruimen", 
  "Organisatie van werk", 
  "Privacy", 
  "Reservering", 
  "Samenwerking", 
  "Sociale interactie", 
  "Teveel geluid", 
  "Thuiswerken", 
  "Waarden & Cultuur", 
  "Werk-prive balans", 
  "WerkTIJD"
];

function CardsPage() {
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const navigate = useNavigate();
  useLanguageChange();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
        navigate("/spelen");
    } else {
        const decodedToken = jwtDecode(token);
        if (decodedToken.role !== "Spelbegeleider") {
          navigate("/spelen");
        }
    }
  }, [navigate]);

  const handleSubjectChange = (subject, isChecked) => {
    if (isChecked) {
      setSelectedSubjects([...selectedSubjects, subject]);
    } else {
      setSelectedSubjects(selectedSubjects.filter(s => s !== subject));
    }
  };

  const goToPreviousPage = () => {
    navigate("/spelenkeuze");
  };

  return (
    <div className="overzichtpage">
      <button className="upperbutton2" onClick={goToPreviousPage}>{i18n.t('PreviousPage')}</button>
      <div className="kaartoverzicht">
        {allSubjects.map(subject => (
          subject === "text" ? 
          <div className="filtertext" key={subject}>
              <span>{i18n.t('FilterInstruction')}</span>
          </div> 
          :
          <div className="filteritemholder" key={subject}>
            <input
              type="checkbox"
              checked={selectedSubjects.includes(subject)}
              onChange={(e) => handleSubjectChange(subject, e.target.checked)}
            />
            <div className="filteritem">
              <span>{subject}</span>
            </div>
          </div>
        ))}
      </div>
      {selectedSubjects.length > 0 ? (
        <div className="kaartkeuzetabel">
          <div className="kaartkeuzeholder">
            <KaartTabel selectedSubjects={selectedSubjects} />
          </div>
        </div>
      ) : (
        <div className="h1holder">
          <h2>{i18n.t('NoFilterSelected')}</h2>
        </div>
      )}
    </div>
  );
}

export default CardsPage;



