import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

const useConfirm = () => {
  const [promise, setPromise] = useState(null);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const confirm = (title, message) => new Promise((resolve, reject) => {
    setTitle(title);
    setMessage(message);
    setPromise({ resolve });
  });

  const handleClose = () => {
    setPromise(null);
  };
  
  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  const ConfirmationDialog = () => (
    <Dialog open={promise !== null} onClose={handleCancel} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm}>Ja</Button>
        <Button onClick={handleCancel}>Nee</Button>
      </DialogActions>
    </Dialog>
  );

  return [ConfirmationDialog, confirm];
};

export default useConfirm;
