// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminkeuze-container {
    padding: 2rem; /* Add padding around the grid */
    background-color: #f5f5f5; /* Light background for contrast */
}

.adminkeuze-title {
    margin-bottom: 2rem; /* Spacing below the title */
    font-family: 'Poppins', sans-serif; /* Add a clean font style */
    color: #333; /* Darker text for better readability */
}

.adminkeuze-grid {
    max-width: 1200px; /* Limit grid width for better UX */
    margin: 0 auto; /* Center the grid */
}

.adminkeuze-grid .adminkeuze-card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: transform 0.3s ease-in-out; /* Add hover effect */
    background-color: rgb(7, 136, 145); /* Card background color */
    border-radius: 8px; /* Rounded corners */
}

.adminkeuze-grid .adminkeuze-card:hover {
    transform: scale(1.05); /* Slight zoom effect */
}

.adminkeuze-grid .adminkeuze-card-text {
    font-family: 'Poppins', sans-serif;
    color: white; /* White text for better contrast */
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AdminkeuzeStyles.css"],"names":[],"mappings":"AAAA;IACI,aAAa,EAAE,gCAAgC;IAC/C,yBAAyB,EAAE,kCAAkC;AACjE;;AAEA;IACI,mBAAmB,EAAE,4BAA4B;IACjD,kCAAkC,EAAE,2BAA2B;IAC/D,WAAW,EAAE,uCAAuC;AACxD;;AAEA;IACI,iBAAiB,EAAE,mCAAmC;IACtD,cAAc,EAAE,oBAAoB;AACxC;;AAEA;IACI,0CAA0C,EAAE,4BAA4B;IACxE,sCAAsC,EAAE,qBAAqB;IAC7D,kCAAkC,EAAE,0BAA0B;IAC9D,kBAAkB,EAAE,oBAAoB;AAC5C;;AAEA;IACI,sBAAsB,EAAE,uBAAuB;AACnD;;AAEA;IACI,kCAAkC;IAClC,YAAY,EAAE,mCAAmC;IACjD,iBAAiB;AACrB","sourcesContent":[".adminkeuze-container {\n    padding: 2rem; /* Add padding around the grid */\n    background-color: #f5f5f5; /* Light background for contrast */\n}\n\n.adminkeuze-title {\n    margin-bottom: 2rem; /* Spacing below the title */\n    font-family: 'Poppins', sans-serif; /* Add a clean font style */\n    color: #333; /* Darker text for better readability */\n}\n\n.adminkeuze-grid {\n    max-width: 1200px; /* Limit grid width for better UX */\n    margin: 0 auto; /* Center the grid */\n}\n\n.adminkeuze-grid .adminkeuze-card {\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */\n    transition: transform 0.3s ease-in-out; /* Add hover effect */\n    background-color: rgb(7, 136, 145); /* Card background color */\n    border-radius: 8px; /* Rounded corners */\n}\n\n.adminkeuze-grid .adminkeuze-card:hover {\n    transform: scale(1.05); /* Slight zoom effect */\n}\n\n.adminkeuze-grid .adminkeuze-card-text {\n    font-family: 'Poppins', sans-serif;\n    color: white; /* White text for better contrast */\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
