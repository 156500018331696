import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import Alert from "../components/Alert";
import { useTranslation } from 'react-i18next';

const Spelbegeleiderlogin = () => {
    const [formData, setFormData] = useState({
        spelcode: '',
        password: ''
    });
    const [loggedIn, setLoggedIn] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            setLoggedIn(true);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            if (data.status === "Error") {
                AlertHandler(data.message);
                return;
            } else {
                localStorage.setItem("token", data.token);
                setLoggedIn(true);
                window.location.reload();
            }
        } catch (error) {
            alert(t('LoginError'), error);
        }
    };

    function AlertHandler(text) {
        setShowAlert(true);
        setAlertTitle(text);
    }

    // Redirect to '/spelenkeuze' if logged in
    if (loggedIn) {
        const token = localStorage.getItem("token");
        const decodedToken = jwtDecode(token);
        if (decodedToken.role === "Admin") {
            navigate("/admin");
        } else if (decodedToken.role === "Spelbegeleider") {
            navigate("/spelenkeuze");
        }
    }

    const backToSpelen = () => {
        navigate('/spelen');
    };

    return (
        <div className="login-register">
            <Alert show={showAlert} handleClose={() => setShowAlert(false)} title={alertTitle} />
            <div className="accountdiv">
                <h5>{t('CoachLogin')}</h5>
                <button className="upperbuttons" onClick={backToSpelen}>{t('Back')}</button>
                <br />
                <h5>{t('GameCode')}:</h5>
                <input 
                    type="text" 
                    name="spelcode" 
                    onChange={(e) => setFormData({ ...formData, spelcode: e.target.value })} 
                    value={formData.spelcode} 
                />
                <h5>{t('Password')}:</h5>
                <input 
                    type="password" 
                    name="password" 
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })} 
                    value={formData.password} 
                />
                <button className="upperbuttons" onClick={handleSubmit}>{t('Login')}</button>
                <a className="wachtwoordvergeten" href="/wachtwoord-vergeten">{t('ForgotPassword')}</a>
                <br />
                <button className="noaccount" onClick={() => navigate("/register/spelbegeleider")}>{t('NoAccount')}</button>          
            </div>
        </div>
    );
}

export default Spelbegeleiderlogin;
