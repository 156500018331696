// Afronding.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode
import i18n from '../i18n'; // Import the i18n instance

const Afronding = () => {
    const { keuze = 'tegelwijsheid', gameId, name } = useParams();
    const navigate = useNavigate();
    const [acties, setActies] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [actiehouder, setActiehouder] = useState([]);
    const [deadline, setDeadline] = useState([]);
    const [voorstel, setVoorstel] = useState('');

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/spelen");
        } else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.role !== "Notulist") {
                navigate("/spelen");
            } else {
                fetchAnswers();
            }
        }
    }, [navigate, gameId, name]);

    const fetchAnswers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/getanswers/${gameId}/${name}`);
            const data = await response.json();
            setQuestions(data.gameSession.map(card => card.cardnumber));
            setAnswers(data.gameSession.map(card => card.card_answers));
            setActies(new Array(data.gameSession.length).fill(''));
        } catch (error) {
            console.error(i18n.t("ErrorFetchingAnswers"), error);
        }
    };

    const handleSubmit = async () => {
        try {
            let contentString = `${i18n.t('TegelwijsheidText')}: ${acties[0]}`;
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/close/${gameId}/${name}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ afrondefase: 'tegelwijsheid', contentDetails: { content: contentString } }),
            });
    
            const responseData = await response.json();
            if (responseData.status === 'success' || responseData.message) {
                navigate('/feedback');
            } else {
                console.error(i18n.t('ErrorClosingGame'), responseData.message);
            }
        } catch (error) {
            console.error(i18n.t("ErrorSubmittingClosingPhase"), error);
        }
    };

    const changeActies = (e, index) => {
        const newActies = [...acties];
        newActies[index] = e.target.value;
        setActies(newActies);
    };

    return (
        <div className="afrondingpage">
            <div className="afrondingtegel">
                <h4>{i18n.t('Tegelwijsheid')}</h4>
                <textarea
                    className="tegeltextarea"
                    placeholder={i18n.t('Tegelwijsheid')}
                    value={acties[0]}
                    onChange={(e) => changeActies(e, 0)}
                />
                <button className="upperbuttons" onClick={handleSubmit}>{i18n.t('Save')}</button>
            </div>
        </div>
    );
};

export default Afronding;
