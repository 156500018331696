import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const WachtwoordVergeten = () => {
    const [code, setCode] = useState(''); // State for storing the code input
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Handle form submission
    const handleSubmit = async () => {
        try {
            // POST request to your backend password reset route
            const response = await fetch(`${process.env.REACT_APP_API_URL}/request-password-reset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },                
                body: JSON.stringify({ code }) // Ensure the body key matches the backend expectations
            });
            
            if (!response.ok) {
                const errorText = await response.text(); // Fetching the error message as text
                throw new Error(errorText);
            }

            const data = await response.json(); // Parsing response data as JSON
            
            if (data.status === "Error") {
                alert(data.message); 
            } else {
                alert(t('PasswordResetLinkSent', { code }));
                navigate('/login/spelbegeleider'); 
            }
        } catch (error) {
            alert(t('RequestError') + ': ' + error.message); 
        }
    };

    return (
        <div className="login-register">
            <div className="accountdiv">
                <h5>{t('PasswordReset')}</h5>
                <h5>{t('GameCode')}:</h5>
                <input 
                    type="text" 
                    value={code} 
                    onChange={(e) => setCode(e.target.value)} 
                /> 
                <button className="upperbuttons" onClick={handleSubmit}>{t('SendLink')}</button>
                <button className="noaccount" onClick={() => navigate(-1)}>{t('Back')}</button>
            </div>
        </div>
    );
}

export default WachtwoordVergeten;
