import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const Keuzemenu2 = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/spelen");
        } else {
            const decodedToken = jwtDecode(token);
            console.log(decodedToken);
            if (decodedToken.role !== "Spelbegeleider") {
                navigate("/spelen");
            }
        }
    }, []);

    return (
        <div className="keuzemenu">
            {/*<div className="kruimelpad">
                <a href="/">Home</a>
                <p>/</p>
                <a href="/spelenkeuze">Dashboard</a>
            </div>*/}
            <div className="keuzes"> 
                <h4>Welkom, spelcoach! Kies wat je wilt gaan doen</h4>
                <ul className="keuzelist">
                    <li>
                        <Link to="/Dashboard">Spelsessies beheren</Link>
                    </li>
                    <li>
                        <Link to="/Archiefpagina">Rapport overzicht</Link>
                    </li>
                </ul>
                <ul className="keuzelist">
                    <li>
                        <Link to="/Kaartkeuze">Spelkaarten overzicht</Link>
                    </li> 
                    <li>
                        <Link to="/DownloadSpelmateriaal">Download spelmateriaal</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Keuzemenu2;
