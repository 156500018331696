import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import i18n from '../i18n';
import { Grid, Card, CardActionArea, CardContent, Typography, Box } from '@mui/material'; // MUI components
import './AdminkeuzeStyles.css'; // Import custom styles

const Adminkeuze = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/spelen");
    } else {
      const decodedToken = jwtDecode(token);
      if (decodedToken.role !== "Admin") {
        navigate("/spelen");
      }
    }
  }, [navigate]);

  const menuItems = [
    { label: i18n.t('GameCodesAndExtend'), link: '/admin/codes' },
    { label: i18n.t('DownloadData'), link: '/admin/data' },
    { label: i18n.t('ViewFeedback'), link: '/admin/feedback' },
    { label: i18n.t('DeleteAccount'), link: '/admin/deleteaccount' },
  ];

  return (
    <Box className="adminkeuze-container">
      <Typography variant="h4" align="center" className="adminkeuze-title">
        {i18n.t('WelcomeAdmin')}
      </Typography>
      <Grid container spacing={3} justifyContent="center" className="adminkeuze-grid">
        {menuItems.map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card className="adminkeuze-card">
              <CardActionArea component={Link} to={item.link}>
                <CardContent>
                  <Typography variant="h6" align="center" className="adminkeuze-card-text">
                    {item.label}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Adminkeuze;
