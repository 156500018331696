// Adminpaneel.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Alert from "../components/Alert";
import i18n from '../i18n'; // Import i18n instance

const AdminDataPage = () => {
  const [archivedGames, setArchivedGames] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedGames, setSelectedGames] = useState([]);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [language, setLanguage] = useState(i18n.language); // Trigger re-render for language changes

  const showAlertMessage = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
    setAlertMessage('');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      const decodedToken = jwtDecode(token);
      if (decodedToken.role !== 'Admin') {
        navigate('/');
      } else {
        fetchArchivedGames(token); // Pass token to the fetch function
      }
    }
  }, [navigate]);

  // Fetch archived games with token in Authorization header
  const fetchArchivedGames = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/archivedgames`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      const data = await response.json();
      setArchivedGames(data);
    } catch (error) {
      console.error(i18n.t('ErrorFetchingArchivedGames'), error);
    }
  };

  // Filter and download games based on date range with token authorization
  const filterAndDownloadGames = async () => {
    const token = localStorage.getItem('token');
    if (!startDate || !endDate) {
      showAlertMessage(i18n.t('EnterBothStartAndEndDate'));
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/filterarchivedgames`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ startDate, endDate }),
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'filtered_games.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error(i18n.t('FailedToDownloadFile'));
      }
    } catch (error) {
      console.error(i18n.t('ErrorDownloadingFilteredGames'), error);
      showAlertMessage(i18n.t('FailedToDownloadFilteredGames'));
    }
  };

  // Handle checkbox change for selected games
  const handleCheckboxChange = (gameId) => {
    setSelectedGames((prevSelectedGames) =>
      prevSelectedGames.includes(gameId)
        ? prevSelectedGames.filter((id) => id !== gameId)
        : [...prevSelectedGames, gameId]
    );
  };

  // Download selected games with token authorization
  const handleDownloadSelectedGames = async () => {
    const token = localStorage.getItem('token');
    if (selectedGames.length === 0) {
      showAlertMessage(i18n.t('SelectAtLeastOneGameToDownload'));
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/downloadcsv`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ gameIds: selectedGames }),
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'selected_games.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error(i18n.t('FailedToDownloadFile'));
      }
    } catch (error) {
      console.error(i18n.t('ErrorDownloadingSelectedGames'), error);
      showAlertMessage(i18n.t('FailedToDownloadSelectedGames'));
    }
  };

  return (
    <div className="admindiv">
      {/* Custom Alert Component */}
      <Alert show={showAlert} handleClose={handleCloseAlert} title={alertMessage} />

      <button className="upperbuttons" onClick={() => navigate('/admin')}>{i18n.t('Back')}</button>
      <h1>{i18n.t('ViewData')}</h1>
      <h5>{i18n.t('GamesForSpecificTimePeriod')}</h5>
      <div className="gespeeldespellen">
        <div className="datefilter">
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            placeholder={i18n.t('StartDate')}
          />
          <h5>{i18n.t('Until')}</h5>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder={i18n.t('EndDate')}
          />
          <button onClick={filterAndDownloadGames}>{i18n.t('Download')}</button>
        </div>
      </div>
      <h5>{i18n.t('AllPlayedGames')}</h5>
      <div className="gameslist">
        {archivedGames.map((game) => (
          <div className="admingames" key={game.id}>
            <span>{new Date(game.createdAt).toISOString().split('T')[0]}</span>
            <input
              type="checkbox"
              checked={selectedGames.includes(game.id)}
              onChange={() => handleCheckboxChange(game.id)}
            />
          </div>
        ))}
        {selectedGames.length > 0 && (
          <button onClick={handleDownloadSelectedGames}>{i18n.t('DownloadSelectedGames')}</button>
        )}
      </div>
    </div>
  );
};

export default AdminDataPage;
