// Feedback.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Alert from "../components/Alert";
import i18n from '../i18n'; // Import for localization

const Feedback = () => {
    const [rating, setRating] = useState(null);
    const [comment, setComment] = useState('');
    const [verbeteringen, setVerbeteringen] = useState('');
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const showAlertMessage = (message) => {
        setAlertMessage(message);
        setShowAlert(true);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
        setAlertMessage('');
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/spelen");
        } else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.role !== "Notulist") {
                navigate("/spelen");
            }
        }
    }, [navigate]);

    const handleRatingClick = (value) => {
        if (rating === value) {
            setRating(null);
            const buttons = document.getElementsByClassName("feedbackbutton");
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].style.backgroundColor = "rgb(0, 238, 255)";
            }
            return;
        } else {
            setRating(value);
            const buttons = document.getElementsByClassName("feedbackbutton");
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].style.backgroundColor = "rgb(0, 238, 255)";
            }
            buttons[value - 1].style.backgroundColor = "rgb(3, 127, 136)";
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (rating === null) {
            showAlertMessage(i18n.t('FeedbackRatingAlert'));
            return;
        }
        if (comment.trim() === '') {
            showAlertMessage(i18n.t('FeedbackCommentAlert'));
            return;
        }
        sendFeedback();
    };

    const sendFeedback = async () => {
        if (rating === "" || comment ==="") {
            showAlertMessage(i18n.t('FeedbackCommentAlert'));
            return;
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/addfeedback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    nummer: rating,
                    details: comment,
                }),
            });
            const responseData = await response.json();
            if (verbeteringen !== '') {
                sendBugfix();
            } else {
                localStorage.removeItem("token");
                window.location.reload();
            }
        } catch (error) {
            console.error(i18n.t('FeedbackSubmitError'), error);
        }
    };

    const sendBugfix = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/addbugreport`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    details: verbeteringen,
                }),
            });
            const responseData = await response.json();
            console.log(responseData);
            localStorage.removeItem("token");
            window.location.reload();
        } catch (error) {
            console.error(i18n.t('FeedbackSubmitError'), error);
        }
    };

    const handleOverslaan = async () => {
        localStorage.removeItem("token");
        window.location.reload();
    };

    return (
        <div className="feedbackcontainer2">
            <div className="feedbackcontainer">
                <h1>{i18n.t('FeedbackExperienceQuestion')}</h1>
                <div className="textareadiv">
                    <div className="textareas">
                        <h5>{i18n.t('FeedbackGameQuestion')}</h5>
                        <div className="feedbackbuttoncontainer">
                            {[1, 2, 3, 4, 5].map((value) => (
                                <button
                                    key={value}
                                    className="feedbackbutton"
                                    onClick={() => handleRatingClick(value)}
                                >
                                    <p>{['😠', '😕', '😐', '🙂', '😊'][value - 1]}</p>
                                </button>
                            ))}
                        </div>
                        <textarea
                            className="feedbackaera"
                            placeholder={i18n.t('FeedbackGamePlaceholder')}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>
                    <div className="textareas">
                        <h5>{i18n.t('FeedbackWebsiteQuestion')}</h5>
                        <textarea
                            className="feedbackaera"
                            placeholder={i18n.t('FeedbackWebsitePlaceholder')}
                            value={verbeteringen}
                            onChange={(e) => setVerbeteringen(e.target.value)}
                        />
                    </div>
                </div>
                <div className="feedbackbuttons">
                    <button
                        className="upperbuttons feedbackbuttonsave"
                        onClick={handleSubmit}
                    >
                        {i18n.t('FeedbackSubmitButton')}
                    </button>
                    <button
                        className="upperbuttons feedbackbuttonoverslaan"
                        onClick={handleOverslaan}
                    >
                        {i18n.t('FeedbackSkipButton')}
                    </button>
                </div>
                {/* Custom Alert Component */}
                <Alert show={showAlert} handleClose={handleCloseAlert} title={alertMessage} />
            </div>
        </div>
    );
};

export default Feedback;
