import React from 'react';
import '../index.css';

const Alert = ({ show, handleClose, handleConfirm, title, type = 'alert' }) => {
    if (!show) return null;

    return (
        <div className="modal-overlay1" onClick={(e) => { e.stopPropagation(); handleClose() }}>
            <div className="modal-content1" onClick={e => e.stopPropagation()}>
                <div className="alertbox">
                    <h5>{title}</h5>
                </div>
                <div className="alert-buttons">
                    {type === 'confirm' ? (
                        <>
                            <button onClick={handleClose} className="upperbuttons">Cancel</button>
                            <button onClick={handleConfirm} className="upperbuttons">Confirm</button>
                        </>
                    ) : (
                        <button onClick={handleClose} className="okbtn">OK</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Alert;
