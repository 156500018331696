import React, { Suspense, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Keuzemenu from './pages/Keuzemenu';
import Spelbegeleiderregister from './login_pages/Spelbegeleider_register';
import Spelbegeleiderlogin from './login_pages/Spelbegeleider_login';
import Notulistlogin from './login_pages/Notulist_login';
import Wachtwoordvergeten from './login_pages/Wachtwoord_vergeten';
import NieuwWachtwoord from './login_pages/Nieuw_wachtwoord';
import Dashboard from './pages/Dashboard';
import Antwoordpagina from './pages/Antwoordpagina';
import Keuzemenu2 from './pages/Keuzemenu2';
import Kaartkeuze from './pages/Kaartkeuze';
import Account from './pages/Account';
import Notes from './pages/Notes';
import Matrix from './pages/Matrix';
import Archiefpagina from './pages/Archiefpagina';
import AutomaticAfronding from './pages/AutomaticAfronding';
import Afronding from './pages/Afronding';
import DownloadSpelmateriaal from './pages/Download-Spelmateriaal';
import Feedback from './pages/Feedback';
import Bugreport from './pages/Bugreport';
import FAQ from './pages/FAQ';
import Adminkeuze from './pages/Adminkeuze';
import CodePage from './pages/AdminCodePage';
import DataPage from './pages/AdminDataPage';
import FeedbackPage from './pages/AdminFeedbackPage';
import AfrondingSelection from './pages/AfrondingSelection';
import './i18n'; // Ensure this is imported for i18n initialization
import AdminDeleteAccount from './pages/AdminDeleteAccount';
import i18n from './i18n';

function App() {
  const location = useLocation();

  // Ensure the saved language is loaded and synchronized with i18n on mount
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'nl';
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  const noHeaderFooterRoutes = [
    /^\/antwoordpagina\/\d+\/[A-Za-z]+$/,
    /^\/matrix\/\d+\/[A-Za-z]+$/,
    /^\/afronding\/tegelwijsheid\/\d+\/[A-Za-z]+$/,
    /^\/afronding\/online\/[A-Za-z]+\/\d+\/[A-Za-z]+$/,
    /^\/afronding-selection\/\d+\/[A-Za-z]+$/, // Added this pattern
    /^\/feedback$/,
    /^\/notes\/\d+\/[A-Za-z]+$/
  ];

  const shouldDisplayHeaderFooter = !noHeaderFooterRoutes.some(route => route.test(location.pathname));

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="App">
        {shouldDisplayHeaderFooter && <Navbar />}

        <div className={shouldDisplayHeaderFooter ? "pages" : "gamepages"}>
          <Routes>
            <Route path="/" element={<Navigate to="/spelen" replace />} /> {/* Redirect from / to /spelen */}
            <Route path="/spelen" element={<Keuzemenu />} />
            <Route path="/spelenkeuze" element={<Keuzemenu2 />} />
            <Route path="/register/spelbegeleider" element={<Spelbegeleiderregister />} />
            <Route path="/login/spelbegeleider" element={<Spelbegeleiderlogin />} />
            <Route path="/login/notulist" element={<Notulistlogin />} />
            <Route path="/wachtwoord-vergeten" element={<Wachtwoordvergeten />} />
            <Route path="/nieuw-wachtwoord" element={<NieuwWachtwoord />} />
            <Route path="/Kaartkeuze" element={<Kaartkeuze />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/account" element={<Account />} />
            <Route path="/notes/:gameId/:name" element={<Notes />} />
            <Route path="/archiefpagina" element={<Archiefpagina />} />
            <Route path="/verbeteren" element={<Bugreport />} />
            <Route path="/DownloadSpelmateriaal" element={<DownloadSpelmateriaal />} />
            <Route path="/FAQ" element={<FAQ />} />
            <Route path="/admin" element={<Adminkeuze />} />
            <Route path="/admin/codes" element={<CodePage />} />
            <Route path="/admin/data" element={<DataPage />} />
            <Route path="/admin/feedback" element={<FeedbackPage />} />
            <Route path="/antwoordpagina/:gameId/:name" element={<Antwoordpagina />} />
            <Route path="/matrix/:gameId/:name" element={<Matrix />} />
            <Route path="/afronding-selection/:gameId/:name" element={<AfrondingSelection />} />
            <Route path="/afronding/tegelwijsheid/:gameId/:name" element={<Afronding />} />
            <Route path="/afronding/online/:keuze/:gameId/:name" element={<Afronding />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/admin/deleteaccount" element={<AdminDeleteAccount />} />
          </Routes>
        </div>

        {shouldDisplayHeaderFooter && <Footer />}
      </div>
    </Suspense>
  );
}

export default App;

