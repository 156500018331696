// DownloadSpelmateriaal.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ActieFile from "./../pdfs/Actie.pdf";
import BesluitFile from "./../pdfs/Besluit.pdf";    
import VoorstelFile from "./../pdfs/Voorstel.pdf";
import TegelwijsheidFile from "./../pdfs/Tegelwijsheid.pdf";
import snelstart from "./../pdfs/snelstart.pdf";
import antwoordform from "./../pdfs/antwoordform.pdf";
import handleiding from "./../pdfs/handleiding.pdf";
import handleidingkaarten from "./../pdfs/handleidingkaart.pdf";
import kaartCodeMatrix from "./../pdfs/KeuzeMatrix.xlsx";
import { jwtDecode } from 'jwt-decode';
import i18n from '../i18n'; // Import the i18n instance
import useLanguageChange from '../hooks/useLanguageChange';

const files = [
    { name: i18n.t("QuickStart"), file: snelstart },
    { name: i18n.t("Action"), file: ActieFile },
    { name: i18n.t("GuideForGameLeaders"), file: handleiding },
    { name: i18n.t("Decision"), file: BesluitFile },
    { name: i18n.t("CardSelectionGuide"), file: handleidingkaarten },
    { name: i18n.t("Proposal"), file: VoorstelFile },
    { name: i18n.t("AnswerForm"), file: antwoordform },
    { name: i18n.t("TileWisdom"), file: TegelwijsheidFile },
    { name: i18n.t("CardOverview"), file: kaartCodeMatrix }
];

const DownloadSpelmateriaal = () => {
    const navigate = useNavigate();
    useLanguageChange();
    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/spelen");
        } else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.role !== "Spelbegeleider") {
                navigate("/spelen");
            }
        }
    }, [navigate]);

    return (
        <div className="downloadpage"> 
            <button className="upperbutton2" onClick={() => navigate('/spelenkeuze')}>
                {i18n.t('PreviousPage')}
            </button>
            <h5>{i18n.t('PageDescription')}</h5>
            <div className="downloadtextcontainer">
                <div className="downloadtext">
                    <h5>{i18n.t('Guides')}</h5>
                    <li>{i18n.t('QuickGuideForPlayers')}</li>
                    <li>{i18n.t('GuideForGameLeaders')}</li>
                    <li>{i18n.t('GuideForDigitalReporting')}</li>
                </div>
                <div className="downloadtext">
                    <h5>{i18n.t('CardSelection')}</h5>
                    <p>{i18n.t('AvailableDocuments')}</p>
                    <li>{i18n.t('GuideForCardSelection')}</li>
                    <li>{i18n.t('OverviewOfAllCards')}</li>
                </div>
            </div>
            <h5>{i18n.t('DownloadFiles')}</h5>
            <div className="downloadscontainer">
                {files.map((file, index) => (
                    <div className="downloads" key={index}>
                        <a href={file.file} download>
                            <button className="upperbuttons">
                                {file.name}
                            </button>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DownloadSpelmateriaal;
