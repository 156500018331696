import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Alert from "../components/Alert";

const AdminDeleteAccount = () => {
  const [userIdCode, setUserIdCode] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();
  const [token, setToken] = useState('');

  useEffect(() => {
    // Check if user is admin
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      const decodedToken = jwtDecode(token);
      if (decodedToken.role !== 'Admin') {
        navigate('/');
      }
      setToken(token); // Store token for authorization
    }
  }, [navigate]);

  const handleSearchUser = async () => {
    if (userIdCode.trim() === '') {
      setAlertMessage('Voer een geldige gebruikerscode in.');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/getuserbycode`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include token in Authorization header
        },
        body: JSON.stringify({ userIdCode }),
      });
      const data = await response.json();
      if (data.error) {
        setUserDetails(null);
        setAlertMessage(data.error);
      } else {
        setUserDetails(data.user);
        setAlertMessage('');
      }
    } catch (error) {
      console.error('Error fetching user:', error);
      setAlertMessage('Er is een fout opgetreden bij het ophalen van de gebruiker.');
    }
  };

  const handleDeleteUser = async () => {
    const isConfirmed = window.confirm(
      'Weet je zeker dat je dit account wilt verwijderen? Dit kan niet ongedaan worden gemaakt.'
    );
  
    if (!isConfirmed) return;
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/deleteuser`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ userId: userDetails.id }),
      });
  
      const data = await response.json();
  
      if (data.success) {
        setAlertMessage('Account succesvol verwijderd.');
        setUserDetails(null);
        setUserIdCode('');
      } else {
        setAlertMessage(data.error);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      setAlertMessage('Er is een fout opgetreden bij het verwijderen van de gebruiker.');
    }
  };
  

  return (
    <div className="admindiv">
      <Alert show={!!alertMessage} handleClose={() => setAlertMessage('')} title={alertMessage} />
      <button className="upperbuttons" onClick={() => navigate('/admin')}>Terug</button>
      <h1>Gebruiker verwijderen</h1>
      <div className="generatordiv">
        <div className="codegenerator">
          <div className='admincodeinput'>
            <input
              type="text"
              value={userIdCode}
              placeholder='Gebruikerscode (8 cijfers)'
              onChange={(e) => setUserIdCode(e.target.value)}
            />
            <button onClick={handleSearchUser}>Zoek gebruiker</button>
          </div>
          {userDetails && (
            <div className="user-details">
              <p><strong>ID:</strong> {userDetails.id}</p>
              <p><strong>Email:</strong> {userDetails.email}</p>
              <p><strong>Bedrijf:</strong> {userDetails.company}</p>
              <button onClick={handleDeleteUser} className="delete-button">Verwijder Account</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDeleteAccount;
