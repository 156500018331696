import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CaptchaComponent from '../components/captcha';
import { useTranslation } from 'react-i18next';

const Spelbegeleiderregister = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    code: '',
    company: '',
  });
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [agreedToPrivacy, setAgreedToPrivacy] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!isCaptchaValid) { 
      alert(t('CaptchaIncorrect'));
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      alert(t('PasswordsDoNotMatch'));
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (data.status === "Success") {
        window.confirm(t('UserCreatedSuccessfully'));
        navigate('/login/spelbegeleider');
      } else {
        alert(data.message);
      }
    } catch (error) {
      alert(t('ErrorCreatingUser'), error);
    }
  };

  // Validate password based on requirements
  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChar;
  };

  // Generate a message for unmet password requirements
  const getPasswordRequirementsMessage = () => {
    const requirements = [
      formData.password.length >= 8 ? null : t('Min8Characters'),
      /[A-Z]/.test(formData.password) ? null : t('OneUppercaseMissing'),
      /[a-z]/.test(formData.password) ? null : t('OneLowercaseMissing'),
      /\d/.test(formData.password) ? null : t('OneNumberMissing'),
      /[!@#$%^&*(),.?":{}|<>]/.test(formData.password) ? null : t('OneSpecialCharMissing')
    ];

    return requirements.filter(r => r !== null).join(", ");
  };

  // Handle password change
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setFormData({ ...formData, password: newPassword });
    setPasswordIsValid(validatePassword(newPassword));
    setPasswordsMatch(newPassword === formData.confirmPassword);
  };

  // Handle successful captcha validation
  const handleCaptchaSuccess = () => {
    setIsCaptchaValid(true);
  };

  return (
    <div className="login-register">
      <div className="accountdiv">
        <button className="upperbuttons" onClick={() => navigate('/login/spelbegeleider')}>{t('Back')}</button>
        <h1>{t('Register')}</h1>
        <br/>
        <h5>{t('Email')}:</h5>
        <input
          type="email"
          name="email"
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          value={formData.email}
        />
        <h5>{t('Password')}:</h5>
        <input
          type="password"
          name="password"
          onChange={handlePasswordChange}
          value={formData.password}
        />
        {passwordIsValid ? null : (
          <div className="password-warning">
            {getPasswordRequirementsMessage()}
          </div>
        )}
        <h5>{t('ConfirmPassword')}:</h5>
        <input
          type="password"
          name="confirmPassword"
          onChange={(e) => {
            setFormData({ ...formData, confirmPassword: e.target.value });
            setPasswordsMatch(e.target.value === formData.password);
          }}
          value={formData.confirmPassword}
        />
        {!passwordsMatch && (
          <div className="password-warning"><p>{t('PasswordsDoNotMatch')}</p></div>
        )}
        <h5>{t('Code')}:</h5>
        <input
          type="text"
          name="code"
          onChange={(e) => setFormData({ ...formData, code: e.target.value })}
          value={formData.code}
        />
        <h5>{t('Company')}:</h5>
        <input
          type="text"
          name="company"
          onChange={(e) => setFormData({ ...formData, company: e.target.value })}
          value={formData.company}
        />
        {isCaptchaValid ? "✔️" :  <CaptchaComponent onCaptchaSuccess={handleCaptchaSuccess} setCaptchaValid={setIsCaptchaValid} />}
       
        <div>
          <input
            className="captchabox"
            type="checkbox"
            checked={agreedToPrivacy}
            onChange={(e) => setAgreedToPrivacy(e.target.checked)}
          />
          <span> {t('AgreeToPrivacy')} <a href="https://www.cfpb.nl/privacy/" target="_blank" rel="noopener noreferrer">
            {t('PrivacyPolicy')}
          </a></span>
        </div>
        <button className="upperbuttons" onClick={handleSubmit} disabled={!agreedToPrivacy}>{t('Register')}</button>
      </div>
    </div>
  );
};

export default Spelbegeleiderregister;
